var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"company_list_item"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"img-wrap"},[_c('el-image',{staticStyle:{"width":"120px","height":"120px","background":"#f2f2f2"},attrs:{"src":_vm.companyInfo.logo ? _vm.companyInfo.logo : _vm.DEFAULT_LOGO,"fit":"scale-down","alt":""}})],1)]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"companyTitle clearfix"},[_c('div',{staticClass:"company fl"},[_c('a',{staticClass:"name",attrs:{"href":'/companyDetail?parameter=' +
              _vm._encode({
                company_id: _vm.companyInfo.id,
              }),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm._f("priorFormat")(_vm.companyInfo.name_en,_vm.companyInfo.name_zh, _vm.LOCALE))+" ")]),_c('el-popover',{attrs:{"placement":"top-start","title":_vm.$t('membershipTime'),"width":"200","trigger":"hover","content":_vm._f("secondFormat")(_vm.companyInfo.end_time,'LL')}},[(_vm.companyInfo.genre_ids.length > 0)?_c('span',{staticClass:"icon",attrs:{"slot":"reference"},slot:"reference"}):_vm._e()])],1),_c('div',{staticClass:"btns fl"},[(_vm.companyInfo.siffa_collection_id === 0)?_c('el-button',{attrs:{"type":"danger","size":"small"},on:{"click":_vm.favorites}},[_c('el-image',{staticClass:"hearticon",staticStyle:{"width":"10px","height":"10px"},attrs:{"src":_vm.heart,"fit":"scale-down","alt":_vm.$t('siffa')}}),_vm._v(" "+_vm._s(_vm.$t("enshrine"))+" ")],1):_vm._e(),(_vm.companyInfo.siffa_collection_id)?_c('el-button',{attrs:{"type":"info","plain":"","size":"small"},on:{"click":_vm.unfavorite}},[_vm._v(_vm._s(_vm.$t("Collected")))]):_vm._e()],1)]),_c('div',{staticClass:"rating"},[(_vm.companyInfo.genre_ids && _vm.companyInfo.genre_ids.length)?_c('span',{staticClass:"text1 mr24"},_vm._l((_vm.companyInfo.genre_ids),function(item,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(_vm._f("textFormat")(_vm._f("unitTypeFormat")(Number(item),_vm.LOCALE)))+" ")])}),0):_vm._e(),(_vm.companyInfo.company_credit == 1)?_c('span',{staticClass:"ratingLevel"},[_vm._v(" "+_vm._s(_vm.$t("creditAAA"))+" ")]):(_vm.companyInfo.company_credit == 2)?_c('span',{staticClass:"ratingLevel"},[_vm._v(" "+_vm._s(_vm.$t("creditAA"))+" ")]):(_vm.companyInfo.company_credit == 3)?_c('span',{staticClass:"ratingLevel"},[_vm._v(" "+_vm._s(_vm.$t("creditA"))+" ")]):_vm._e()]),_c('div',{staticClass:"business"},[(_vm.showType === 1)?[_c('div',{staticClass:"contact"}),_c('div',{staticClass:"information"})]:_vm._e(),(_vm.showType === 2)?[_c('span',{staticClass:"text1 mr24"},[_vm._v(" "+_vm._s(_vm.$t("companyGoodBusiness"))+" ")]),(
            _vm.companyInfo.business_classification_list &&
              _vm.companyInfo.business_classification_list.length > 0
          )?_vm._l((_vm.companyInfo.business_classification_list),function(item,index){return _c('el-tag',{key:index,staticClass:"item",attrs:{"type":"info","size":"small"}},[_vm._v(" "+_vm._s(_vm._f("priorFormat")(item.desc_en,item.desc_zh, _vm.LOCALE))+" ")])}):_c('span',[_vm._v("-")])]:_vm._e()],2),_c('div',{staticClass:"website"},[(_vm.showType === 2)?[_c('div',{staticClass:"officialWebsite textOverflow"},[_c('span',{staticClass:"text1 mr52"},[_vm._v(_vm._s(_vm.$t("company_net")))]),(
              _vm.companyInfo.website && _vm.companyInfo.website.indexOf('http') != -1
            )?_c('span',[_c('a',{staticClass:"blueColor",attrs:{"href":_vm.companyInfo.website,"target":"_blank","rel":"nofollow"}},[_vm._v(" "+_vm._s(_vm._f("textFormat")(_vm.companyInfo.website)))])]):_c('span',[_c('a',{staticClass:"blueColor",attrs:{"href":'http://' + _vm.companyInfo.website,"target":"_blank","rel":"nofollow"}},[_vm._v(" "+_vm._s(_vm._f("textFormat")(_vm.companyInfo.website)))])])]),_c('div',{staticClass:"address textOverflow"},[_c('span',{staticClass:"text1 mr52"},[_vm._v(_vm._s(_vm.$t("address")))]),_c('span',{attrs:{"title":_vm._f("priorFormat")(_vm.companyInfo.address_en,_vm.companyInfo.address_zh, _vm.LOCALE)}},[_vm._v(" "+_vm._s(_vm._f("priorFormat")(_vm.companyInfo.address_en,_vm.companyInfo.address_zh, _vm.LOCALE))+" ")])])]:_vm._e()],2),(_vm.videoShow)?_c('div',{staticClass:"video"},[_vm._l((_vm.companyInfo.video_info),function(item,id){return [(id < _vm.number)?_c('div',{key:id,staticClass:"fl",staticStyle:{"margin-right":"18px","width":"200px","height":"151px"}},[_c('videoPlay',{ref:"videoPlay",refInFor:true,staticClass:"itemBox"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.aliyun_id),expression:"item.aliyun_id"}],staticClass:"iconDiv"},[_c('i',{staticClass:"el-icon-video-play iconPlay",on:{"click":function($event){return _vm.allplayVideo(item.aliyun_id)}}})]),_c('el-image',{staticStyle:{"width":"200px","height":"151px"},attrs:{"src":item.video_cover_url || item.cover_url,"fit":"cover","alt":_vm.$t('siffa')}})],1)],1):_vm._e()]}),_vm._l((_vm.companyInfo.picture_info),function(item,index){return [(_vm.companyInfo.video_info.length + index < _vm.number)?_c('div',{key:item.id,staticClass:"fl",staticStyle:{"margin-right":"18px"},attrs:{"alt":_vm.$t('siffa')}},[_c('el-image',{staticClass:"coverImg",staticStyle:{"width":"200px","height":"151px","background":"#ccc"},attrs:{"fit":'contain',"src":item.url,"alt":_vm.$t('siffa'),"preview-src-list":[item.url]}})],1):_vm._e()]}),(
          _vm.companyInfo.video_info.length + _vm.companyInfo.picture_info.length >
            _vm.number
        )?_c('div',{staticClass:"palyVideo fl",on:{"click":_vm.goToDetail}},[_c('el-image',{staticClass:"coverImg",staticStyle:{"width":"200px","height":"151px"},attrs:{"src":_vm.cover,"alt":_vm.$t('siffa')}}),_c('div',{staticClass:"mark",staticStyle:{"line-height":"151px"}},[_c('i',{staticClass:"ppp"},[_vm._v(_vm._s(_vm.$t("companyLookMore"))+"...")])])],1):_vm._e()],2):_vm._e()]),_c('videoPlay',{ref:"allvideoplay"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }